import React, { useState } from 'react';
import Seo from '../components/Seo';
import { indexHolder, headerHolder, formHolder, logoHolder } from './index.module.scss';
import { AnimatePresence, motion } from 'framer-motion';
import { StaticImage } from 'gatsby-plugin-image';
import { Button, TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import addToMailchimp from 'gatsby-plugin-mailchimp';

const ingredients = ['Cinnamon bark', 'Rapeseed oil', 'Star anise', 'Onions', 'Garlic', 'Chilli', 'Salt', 'Sugar', 'Flour'];

const formVariants = {
  initial: {
    opacity: 0,
    y: -30,
    x: '0%',
  },
  animate: {
    opacity: 1,
    y: 40,
    x: '0%',
    transition: {
      duration: 0.5,
      ease: [0.65, 0, 0.35, 1],
    },
  },
  exit: {
    opacity: 0,
    y: 20,
    transition: {
      duration: 0.5,
      ease: [0.65, 0, 0.35, 1],
    },
  },
};
const resultsVariants = {
  initial: {
    opacity: 0,
    y: -20,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
      type: 'tween',
    },
  },
  exit: {
    opacity: 0,
    y: 20,
    transition: {
      duration: 0.5,
      type: 'tween',
    },
  },
};
const allGoodVariants = {
  initial: { opacity: 1 },
  animate: {
    opacity: 1,
    transition: {
      duration: 4,
      staggerChildren: 0.4,
      when: 'beforeChildren',
    },
  },
  exit: {
    transition: {
      duration: 0.5,
      staggerChildren: 0.1,
      when: 'afterChildren',
      delay: 1.2,
    },
  },
};

const spanVariants = {
  initial: {
    opacity: 0,
    y: '100%',
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1,
      type: 'tween',
      ease: [0.65, 0, 0.35, 1],
    },
  },
  exit: {
    opacity: 0,
    y: '-100%',
    transition: {
      duration: 0.5,
      delay: 1.2,
    },
  },
};

const ingredientsUl = {
  initial: { x: 0 },
  animate: {
    x: 0,
    transition: {
      staggerChildren: 0.25,
      when: 'beforeChildren',
      delay: 2.1,
    },
  },
  exit: {
    x: 0,
    transition: {
      ease: [0.65, 0, 0.35, 1],
      delay: 1.2,
      duration: 0.5,
    },
  },
};

const featuresVariants = {
  initial: { x: 0 },
  animate: {
    x: 0,
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
      when: 'beforeChildren',
      delay: 1.5,
    },
  },
  exit: {
    opacity: 0,
    x: 0,
    transition: { delay: 1.2 },
  },
};

const ingredientsVariants = {
  initial: {
    opacity: 0,
    x: '100%',
  },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.5,
      type: 'tween',
      ease: [0.65, 0, 0.35, 1],
    },
  },
  exit: {
    opacity: 0,
    x: '100%',
    transition: {
      duration: 1,
      type: 'tween',
      delay: 1.2,
    },
  },
};

const chilliVariants = {
  initial: {
    opacity: 0,
    x: '100%',
  },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      delay: 1.3,
      duration: 1.2,
      type: 'tween',
      ease: [0.65, 0, 0.35, 1],
    },
  },
  exit: {
    opacity: 0,
    x: '50%',
    transition: {
      duration: 1,
      type: 'tween',
      ease: [0.65, 0, 0.35, 1],
      delay: 1.2,
    },
  },
};

const planetVariants = {
  initial: {
    x: 0,
  },
  animate: {
    x: 0,
    transition: {
      delay: 2.3,
      staggerChildren: 0.2,
      when: 'beforeChildren',
    },
  },
  exit: {},
};

const planetSpanVariants = {
  initial: {
    opacity: 0,
    x: '100%',
  },
  animate: {
    opacity: 1,
    transition: {
      duration: 0.4,
      type: 'tween',
      ease: [0.65, 0, 0.35, 1],
    },
  },
  exit: {
    opacity: 0,
    transition: { delay: 1.2 },
    x: '-100%',
  },
};

const articleVariants = {
  initial: { y: 0 },
  animate: { y: 0, opacity: 1 },
  exit: {
    y: 100,
    opacity: 0,
    transition: {
      duration: 0.6,
      delay: 1.2,
      type: 'tween',
    },
  },
};

const topLeftVariants = {
  initial: { x: -1000, opacity: 0, scale: 3 },
  animate: {
    x: 0,
    opacity: 1,
    scale: 1,
    transition: {
      duration: 1.4,
      type: 'tween',
      ease: [0.65, 0, 0.35, 1],
    },
  },
};

const topLeftRectVariants = {
  initial: { pathLength: 0 },
  animate: {
    pathLength: 1,
    transition: {
      duration: 1.4,
      ease: [0.65, 0, 0.35, 1],
    },
  },
};

const circleVariants = {
  initial: { y: -800, opacity: 0 },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 1.4,
      type: 'tween',
      ease: [0.65, 0, 0.35, 1],
      delay: 0.8,
    },
  },
};

const topRightSvgVariants = {
  initial: { y: -200, x: 1000, opacity: 0, scale: 3 },
  animate: {
    y: 0,
    x: 0,
    opacity: 1,
    scale: 1,
    transition: {
      duration: 1.5,
      type: 'tween',
      ease: [0.65, 0, 0.35, 1],
      delay: 0.5,
    },
  },
};

const topRightRectVariants = {
  initial: { pathLength: 0 },
  animate: {
    pathLength: 1,
    transition: {
      duration: 1.4,
      ease: [0.65, 0, 0.35, 1],
    },
  },
};

const bottomSvgVariants = {
  initial: {
    y: 500,
    opacity: 0,
    scale: 3,
  },
  animate: {
    y: 0,
    opacity: 1,
    scale: 1,
    transition: {
      duration: 1.4,
      type: 'tween',
      ease: [0.65, 0, 0.35, 1],
      delay: 0.6,
    },
  },
};

const bottomRectVariants = {
  initial: { pathLength: 0 },
  animate: { pathLength: 1, transition: { duration: 1, ease: [0.65, 0, 0.35, 1] } },
};

const textureVariants = {
  initial: {
    opacity: 0,
    scale: 0.4,
  },
  animate: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 1.1,
      type: 'tween',
      ease: [0.65, 0, 0.35, 1],
    },
  },
};

const logoVariants = {
  initial: {
    x: '13%',
    rotate: '0deg',
    y: '120%',
    scale: 1,
  },
  preIntro: {
    x: '13%',
    y: '25%',
    rotate: '0deg',
    scale: 1,
    transition: {
      duration: 1,
      type: 'tween',
      ease: 'circOut',
    },
  },
  animate: {
    y: '0%',
    x: '-100%',
    rotate: '-90deg',
    scale: 1,
    transition: {
      duration: 1,
      type: 'tween',
      ease: [0.65, 0, 0.35, 1],
    },
  },
  postIntro: {
    x: '32%',
    rotate: '0deg',
    y: '2%',
    opacity: 1,
    scale: 0.6,
    transition: {
      duration: 1.3,
      type: 'tween',

      ease: [0.65, 0, 0.35, 1],
    },
  },
};

const logoPathVariants = {
  initial: {
    pathLength: 0,
    fill: 'rgba(0,0,0,0)',
  },
  preIntro: {
    pathLength: 1,
    fill: ['rgba(0,0,0,0)', 'rgba(0,0,0,0)', 'rgba(0,0,0,1)'],
    transition: {
      duration: 2,
      type: 'tween',
      ease: [0.65, 0, 0.35, 1],
    },
  },
  logoIntroduction: {
    pathLength: 1,
    fill: 'rgba(0,0,0,1)',
    transition: {
      duration: 1,
    },
  },
  animate: {
    pathLength: 1,
    fill: 'rgba(0,0,0,1)',
  },
  postIntro: {
    pathLength: 1,
    fill: 'rgba(0,0,0,1)',
  },
};

function template({ rotate, x, y, scale }) {
  return `rotate(${rotate}) translateX(${x}) translateY(${y}) scale(${scale})`;
}

const validationSchema = yup.object({
  firstName: yup.string('Enter your first name').required('First name is required'),
  lastName: yup.string('Enter your last name').required('Last name is required'),
  emailAddress: yup.string('Enter your email').email('Enter a valid email').required('Email is required'),
});

export default function IndexPage() {
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      emailAddress: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const result = await addToMailchimp(values.emailAddress, {
        FNAME: values.firstName,
        LNAME: values.lastName,
      });

      console.log(result);
      if (result.result === 'success') {
        setFormResults('success');
      } else {
        setFormResults('error');
      }
    },
  });

  const [introEnded, setIntroEnded] = useState(false);
  const [phase, setPhase] = useState('preIntro');
  const [formResults, setFormResults] = useState('');

  return (
    <>
      <Seo title="Introducing OT Chilli Oil" key="seo" />
      <motion.header className={headerHolder} key="header">
        <h1>OT Chilli Oil</h1>
      </motion.header>
      <motion.div className={logoHolder} key="logo">
        <motion.div className="logoInner">
          <motion.svg
            width="797"
            height="541"
            viewBox="0 0 797 541"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            variants={logoVariants}
            onAnimationComplete={() => {
              if (phase === 'preIntro') setPhase('animate');
            }}
            initial="initial"
            animate={phase}
            exit="exit"
            transformTemplate={template}
            style={{ originX: 0, originY: 0 }}>
            <motion.path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M577.185 525.467V172.424C577.185 172.424 576.701 168.938 577.185 167.498C577.67 166.057 574.73 159.387 567.333 163.277C559.935 167.167 532.439 195.823 532.439 195.823L477.43 251.653C477.43 251.653 473.325 254.415 473.325 247.959V155.593C473.325 155.593 473.908 149.615 477.43 149.435C480.952 149.255 788.601 149.435 788.601 149.435C788.601 149.435 795.694 147.746 795.99 154.772C796.286 161.798 795.99 249.19 795.99 249.19C795.99 249.19 795.296 255.178 790.653 250.832C786.01 246.486 707.729 167.498 707.729 167.498C707.729 167.498 704.191 164.42 699.929 163.277C695.668 162.134 692.946 161.526 692.13 166.676C691.313 171.827 691.719 208.96 691.719 208.96V526.699C691.719 526.699 691.982 531.625 685.561 531.625H582.932C582.932 531.625 577.185 533.727 577.185 525.467Z"
              stroke="black"
              strokeWidth="1"
              variants={logoPathVariants}
            />
            <motion.path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M302.983 0.828125L354.686 48.0374L179.806 114.541L141.628 80.4682C141.628 80.4682 209.664 49.5005 225.784 41.0587C241.903 32.6168 302.983 0.828125 302.983 0.828125Z"
              stroke="black"
              strokeWidth="1"
              variants={logoPathVariants}
            />
            <motion.path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M204.891 146C215.311 146 242.087 146.631 242.087 146.631H409.783L435 211.623L359.348 205.313C359.348 205.313 365.467 210.932 368.294 214.33C396.312 248.007 409.783 296.457 409.783 342.883C409.783 393.601 392.249 444.794 359.421 479.715C321.914 519.61 264.546 541 204.243 541C147.509 541 92.2558 521.669 55.1386 485.819C18.2746 450.212 0 397.029 0 342.883C0 295.185 15.6354 245.67 45.0498 211.582C82.645 168.014 141.448 146 204.891 146ZM285.656 306.302C285.656 306.302 301.575 359.389 302.097 405.771C302.619 452.152 293.951 475.609 283.601 492.498C273.251 509.388 252.796 517.353 242.91 517.982C233.025 518.611 192.373 519.289 152.898 449.34C126.209 402.048 114.209 347.361 109.33 318.633C104.463 289.969 105.524 241.35 113.029 214.642C120.535 187.935 134.582 166.111 155.364 163.674C176.146 161.237 201.068 166.277 230.991 199.845C260.914 233.413 280.036 286.119 285.656 306.302Z"
              stroke="black"
              strokeWidth="1"
              variants={logoPathVariants}
            />
          </motion.svg>
        </motion.div>
      </motion.div>
      <AnimatePresence exitBeforeEnter>
        {!introEnded && (
          <motion.article
            className={indexHolder}
            key="intro"
            variants={articleVariants}
            initial="initial"
            animate={phase}
            exit="exit"
            onAnimationComplete={() => {
              if (phase === 'animate') {
                setPhase('postIntro');
                setIntroEnded(true);
              }
            }}>
            <div className="indexInner">
              <div className="top">
                <div className="topLeft">
                  <motion.h3 className="allGood" variants={allGoodVariants}>
                    <motion.span variants={spanVariants}>It's</motion.span> <motion.span variants={spanVariants}>all</motion.span>{' '}
                    <motion.span variants={spanVariants}>good</motion.span>
                  </motion.h3>
                  <motion.svg
                    width="300"
                    height="900"
                    viewBox="0 0 300 900"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    variants={topLeftVariants}
                    preserveAspectRatio="none">
                    <motion.rect x="0.5" y="0.5" width="299" height="899" stroke="black" variants={topLeftRectVariants} />
                  </motion.svg>
                </div>

                <div className="topRight">
                  <motion.ul variants={ingredientsUl}>
                    {ingredients.map((ingredient) => (
                      <motion.li key={ingredient} variants={ingredientsVariants}>
                        {ingredient}
                      </motion.li>
                    ))}
                  </motion.ul>
                  <motion.p className="planet" variants={planetVariants}>
                    <motion.span variants={planetSpanVariants}>Vegan</motion.span> <motion.span variants={planetSpanVariants}>Chilli</motion.span>{' '}
                    <motion.span variants={planetSpanVariants}>Oil</motion.span>
                  </motion.p>
                  <div className="circleHolder">
                    <motion.svg
                      className="circle"
                      width="674"
                      height="674"
                      viewBox="0 0 674 674"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      variants={circleVariants}>
                      <circle cx="337" cy="337" r="337" fill="#D3B173" />
                    </motion.svg>
                  </div>
                  <motion.div className="chilliHolder" variants={chilliVariants}>
                    <StaticImage src="../images/chilli.png" alt="Chilli" className="chilli" placeholder="blurred" width={900} />
                  </motion.div>

                  <motion.svg
                    className="border"
                    width="700"
                    height="900"
                    viewBox="0 0 700 900"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    variants={topRightSvgVariants}
                    preserveAspectRatio="none">
                    <motion.rect x="0.5" y="0.5" width="699" height="899" stroke="black" variants={topRightRectVariants} />
                  </motion.svg>
                </div>
              </div>
              <div className="bottom">
                <motion.ul variants={featuresVariants}>
                  <motion.li variants={spanVariants}>Vegan</motion.li>
                  <motion.li variants={spanVariants}>Organic</motion.li>
                  <motion.li variants={spanVariants}>Nut Free</motion.li>
                  <motion.li variants={spanVariants}>Gluten free</motion.li>
                </motion.ul>
                <motion.svg
                  width="1000"
                  height="100"
                  viewBox="0 0 1000 100"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  variants={bottomSvgVariants}
                  preserveAspectRatio="none">
                  <motion.rect x="0" y="0" width="999" height="99" stroke="black" variants={bottomRectVariants} />
                </motion.svg>
              </div>
            </div>

            <motion.div className="texture" key="texture" variants={textureVariants}>
              <StaticImage src="../images/texture.jpg" alt="Paper texture" quality={80} />
            </motion.div>
          </motion.article>
        )}
        {introEnded && (
          <motion.div className={formHolder} key="form" variants={formVariants} initial="initial" animate="animate" exit="exit">
            {/* <h2>OT Chilli Oil</h2> */}
            <h4>Coming Soon</h4>
            <AnimatePresence exitBeforeEnter>
              {formResults === '' ? (
                <motion.div key="form" variants={resultsVariants} initial="initial" animate="animate" exit="exit">
                  <p className="pleaseSubscribe">Please subscribe for updates</p>
                  <form id="mailchimpForm" onSubmit={formik.handleSubmit}>
                    <TextField
                      id="firstName"
                      label="First name"
                      variant="standard"
                      className="firstName"
                      required={true}
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                      helperText={formik.touched.firstName && formik.errors.firstName}
                    />
                    <TextField
                      id="lastName"
                      label="Last name"
                      variant="standard"
                      className="lastName"
                      required={true}
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                      helperText={formik.touched.lastName && formik.errors.lastName}
                    />
                    <TextField
                      id="emailAddress"
                      label="Email address"
                      variant="standard"
                      className="emailAddress"
                      required={true}
                      value={formik.values.emailAddress}
                      onChange={formik.handleChange}
                      error={formik.touched.emailAddress && Boolean(formik.errors.emailAddress)}
                      helperText={formik.touched.emailAddress && formik.errors.emailAddress}
                    />
                    <Button type="submit" variant="outlined">
                      Send
                    </Button>
                  </form>
                </motion.div>
              ) : formResults === 'success' ? (
                <motion.div className="success" key="success" variants={resultsVariants} initial="initial" animate="animate" exit="exit">
                  <p>Thank you for subscribing.</p>
                  <p>We'll be in touch!</p>
                </motion.div>
              ) : (
                <motion.div className="error" key="error" variants={resultsVariants} initial="initial" animate="animate" exit="exit">
                  <p>Sorry something went wrong.</p>
                  <p>Please try again.</p>
                  <Button
                    onClick={() => {
                      setFormResults('');
                      formik.resetForm();
                    }}
                    variant="outlined">
                    Try again
                  </Button>

                  <a href="http://eepurl.com/h8FWHH" target="_blank" rel="noreferrer">
                    or try the form on this page.
                  </a>
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}
